import { i18n } from 'next-i18next';
import { Language, languageSelection } from '@/Translations';
import isClient from '@/Util/globals';

export const loadTranslationsConfig = () => {
  const translations: any = {};

  if (i18n && i18n.isInitialized) {
    for (const lng of languageSelection) {
      const translationsObject = i18n.getResourceBundle(lng, 'klaro');
      if (translationsObject) {
        translations[lng] = translationsObject;
      }
    }
  }
  return translations;
};

export const getLanguageKlaro = () => {
  if (!isClient()) return Language.NL.toLocaleLowerCase();
  const formattedPathname = `${window.location.pathname}/`;
  const splittedPath = formattedPathname.split('/');
  const foundLanguage = Object.values(Language).find(lng => {
    if (splittedPath.includes(lng)) return lng;
  });
  return foundLanguage || Language.NL;
};

export const defaultTranslations = {
  en: {
    header: 'But first, Cookies!',
    consentNotice: {
      description:
        'Hello, can we offer you some additional services for Analytics? You can always change or withdraw your consent later.',
      ok: 'I agree'
    },
    consentModal: {
      title: '<h3>Consent Settings</h3>',
      description:
        'Hi! We use  cookies on our website. Some are essential, others help us to improve your user experience. Please choose how much data you would like to share. You can always change or withdraw your consent later. Here you can see and customize the information that we collect about you.'
    },
    googleAnalytics: {
      title: 'Google Analytics',
      description: 'Collecting anonymous usage data to improve the content served on this website.'
    },
    necessary: {
      title: 'Strictly necessary',
      description:
        'These trackers are used for activities that are strictly necessary to operate or deliver the service you requested from us and, therefore, do not require you to consent.'
    },
    purposes: {
      analytics: 'Analytics',
      functional: 'Functional'
    },
    button: {
      decline: 'No Cookies for me',
      acceptSelected: 'Accept selected',
      acceptAll: 'Accept all'
    }
  }
};

export const addModalHeader = () => {
  const loadedTranslations = loadTranslationsConfig();
  const langUsed = getLanguageKlaro();
  const translations =
    loadedTranslations[langUsed] !== undefined ? loadedTranslations : defaultTranslations;
  const modal = document.querySelector('.cn-body');
  const header = document.createElement('div');
  header.innerHTML = translations[langUsed].header
    ? translations[langUsed].header
    : defaultTranslations.en.header;
  header.className = 'cn-header';
  modal?.insertBefore(header, modal.firstChild);
};

export const moveAnalyticsButton = () => {
  const learnMoreButton = document.querySelector('.cm-btn-lern-more') as HTMLButtonElement | null;
  const noticeParagraph = document.querySelector('.cn-body p') as HTMLParagraphElement | null;
  const cookieNoticeSpan = document.querySelector('.cookie-notice span') as HTMLElement | null;
  if (learnMoreButton && noticeParagraph && cookieNoticeSpan) {
    noticeParagraph.appendChild(learnMoreButton);
    learnMoreButton.className = 'contentNotice';
    const cleanText = cookieNoticeSpan.textContent?.trim() || '';
    learnMoreButton.textContent = cleanText;
    const paragraphWrapper = document.createElement('p');
    paragraphWrapper.textContent = cleanText;
    learnMoreButton.innerHTML = '';
    learnMoreButton.appendChild(paragraphWrapper);
    cookieNoticeSpan.remove();
  }
};

export const setKlaroElements = () => {
  function addHideButtonListener(): void {
    const hideButton: HTMLButtonElement | null = document.querySelector('.klaro .hide');
    if (hideButton) {
      hideButton.addEventListener('click', () => {
        moveAnalyticsButton();
        addModalHeader();
      });
    }
  }
  const observer: MutationObserver = new MutationObserver(() => {
    addHideButtonListener();
  });
  const klaroElement: HTMLElement | null = document.querySelector('.klaro');
  if (klaroElement) {
    observer.observe(klaroElement, {
      childList: true,
      subtree: true
    });
  }
  addHideButtonListener();
};
