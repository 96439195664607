import YsnConfigModel from '@/Config/YsnConfigModel';
import {
  Api,
  ApiAirportType,
  ApiFilter,
  ApiFilterType,
  ApiFlightSearchGeoUnitsRequestFromJSON,
  ApiGeoUnit,
  ApiGeoUnitFromJSON,
  ApiGetPackagesRequest,
  ApiGetPackagesRequestFromJSON,
  ApiGetPackagesResponse,
  ApiGroupedMultipleSelectionFilter,
  ApiItemType,
  ApiListOptions,
  ApiMultipleSelectionFilter,
  ApiProductData,
  ApiSearchProductDataByCodeRequest,
  ApiSearchProductDataByCodeRequestFromJSON,
  ApiSearchProductDataRequestFromJSON,
  ApiSorting
} from '@ibe/api';
import {
  DestinationItem,
  DestinationItemCategory,
  PackageParams,
  PackageSearchStore,
  PackageStore
} from '@ibe/components';
import { Theme } from '@emotion/react';
export const MAX_NUMBER_OF_TRAVELERS_ON_ONE_BOOKING = 9;
import {
  ApiService,
  ConfigModel,
  DurationFactory,
  removeUndefinedProps,
  SessionStorage
} from '@ibe/services';
import { cloneDeep, sortBy } from 'lodash-es';

import Keys from '@/Translations/generated/en/package.json.keys';

import dayjs from 'dayjs';
import { runInAction } from 'mobx';

export const getParentIdFromGeoUnit = (geoUnits: ApiGeoUnit[], code: string) => {
  return geoUnits
    .flatMap(geoUnit => {
      const { children, ...rest } = geoUnit;
      return { ...rest, children: Object.values(children) };
    })
    .find(geoUnit => geoUnit.children.find(child => child.code === code))?.id;
};

export const findChildrenGeoUnitForParent = (
  list: ApiGeoUnit[],
  parentCode: string
): DestinationItem[] => {
  for (let item of list) {
    if (item.code === parentCode) {
      return [
        ...Object.entries(item.children)
          .sort((a, b) => a[1].name?.localeCompare(b[1].name))
          .map(([, value]) => ({
            id: value.id,
            name: value.name,
            code: value.code,
            typeCode: value.typeCode,
            category: DestinationItemCategory.GEO_UNIT,
            parentId: item.id
          }))
      ];
    }
  }
  return [];
};

export const findDestinationGeoUnits = (
  list: ApiGeoUnit[],
  code: string
): DestinationItem[] | undefined => {
  for (let item of list) {
    if (item.code === code) {
      return [
        ...Object.entries(item.children)
          .sort((a, b) => a[1].name?.localeCompare(b[1].name))
          .map(([, value]) => ({
            id: value.id,
            name: value.name,
            code: value.code,
            typeCode: value.typeCode,
            category: DestinationItemCategory.GEO_UNIT,
            parentId: item.id
          })),
        {
          id: item.id,
          name: item.name,
          code: item.code,
          typeCode: item.typeCode,
          category: DestinationItemCategory.GEO_UNIT,
          parentId: getParentIdFromGeoUnit(list, code)
        }
      ]; // return chd separately
    }
    for (let childCode in item.children) {
      if (childCode === code) {
        const child = item.children[childCode];
        return [
          {
            id: child.id,
            name: child.name,
            code: child.code,
            typeCode: child.typeCode,
            category: DestinationItemCategory.GEO_UNIT,
            parentId: item.id
          }
        ];
      }
    }
  }
  return undefined;
};

export const findDestinationProducts = (
  list: ApiProductData[],
  code: string
): DestinationItem | undefined => {
  const foundProduct = list.find(ls => ls.code === code);
  if (foundProduct) {
    return {
      id: foundProduct.id,
      name: foundProduct.description,
      code: foundProduct.code,
      typeCode: foundProduct.itemType,
      category: DestinationItemCategory.PRODUCT
    } as DestinationItem;
  }

  return undefined;
};

export const enrichSearchParamsCodes = (
  params: Partial<PackageParams>,
  enrichmentData: UrlEnrichmentData
) => {
  const destinationsProducts = enrichmentData.destinations.products;
  const destinationsGeoUnits = enrichmentData.destinations.geoUnits;
  if (
    !!params.destinations &&
    params.destinations.length > 0 &&
    (destinationsGeoUnits.length > 0 || destinationsProducts.length > 0)
  ) {
    const resultDestinations: DestinationItem[] = [];
    const resultMainServiceCodes: string[] = [];
    params.destinations.forEach(destinationItem => {
      const foundDestinationGeoUnit = findDestinationGeoUnits(
        destinationsGeoUnits,
        destinationItem.code
      );
      const foundDestinationProduct = findDestinationProducts(
        destinationsProducts,
        destinationItem.code
      );
      if (foundDestinationGeoUnit) {
        resultDestinations.push(
          ...foundDestinationGeoUnit.filter(
            fd => !resultDestinations.find(rd => fd.code === rd.code)
          )
        );
      }
      if (foundDestinationProduct) {
        resultMainServiceCodes.push(foundDestinationProduct.code);
        resultDestinations.push(foundDestinationProduct);
      }
    });

    params.destinations = resultDestinations;
    params.mainServiceCodes = resultMainServiceCodes;
  }

  const { origins } = enrichmentData;
  if (!!params.origins && params.origins.length > 0 && origins.length > 0) {
    const resultOrigins: ApiGeoUnit[] = [];
    params.origins.forEach(origin => {
      const foundOrigin = origins.find(o => o.code === origin.code);
      if (foundOrigin) {
        resultOrigins.push(foundOrigin);
      }
    });
    params.origins = [...new Set(resultOrigins)];
  }
  return params;
};

export const fetchDestinationsGeoUnits = async (api: ApiService): Promise<ApiGeoUnit[]> => {
  const destinationsRQ = ApiSearchProductDataRequestFromJSON({
    type: 'SearchProductDataRequest',
    search: '',
    offset: 0,
    limit: 100,
    itemType: ApiItemType.HOTEL
  });
  const destinationsRS = await api.searchProductData(destinationsRQ);
  return destinationsRS.geoUnits;
};

export const fetchDestinationsProducts = async (
  api: ApiService,
  codes: DestinationItem[]
): Promise<ApiProductData[]> => {
  let products: ApiProductData[] = [];
  const productCodes = codes.filter(
    code => !code.category || code?.category === DestinationItemCategory.PRODUCT
  );
  if (productCodes.length) {
    const destinationsRQ = ApiSearchProductDataByCodeRequestFromJSON({
      codes: productCodes.flatMap(code => code.code).filter(code => code)
    } as ApiSearchProductDataByCodeRequest);
    const destinationsRS = await api.searchProductDataByCode(destinationsRQ);
    products = destinationsRS.products;
  }

  return products;
};

export const fetchOriginsGeoUnits = async (api: ApiService): Promise<ApiGeoUnit[]> => {
  const originsRQ = ApiFlightSearchGeoUnitsRequestFromJSON({
    type: 'FlightSearchGeoUnitsRequest',
    search: '',
    offset: 0,
    limit: 200,
    itemType: ApiItemType.TRANSPORT,
    airportType: ApiAirportType.ORIGIN
  });
  const originsRS = await api.searchGeoUnits(originsRQ);
  return originsRS.geoUnits;
};

export const originsChanged = (oldGeoUnits: ApiGeoUnit[], newGeoUnitCodes: string[]) => {
  const oldCodes = oldGeoUnits.map(gu => gu.code);
  return new Set(oldCodes) === new Set(newGeoUnitCodes);
};

export interface UrlEnrichmentData {
  destinations: {
    geoUnits: ApiGeoUnit[];
    products: ApiProductData[];
  };
  origins: ApiGeoUnit[];
}

function isWithinTheRange(config: YsnConfigModel, startDate?: string, endDate?: string) {
  const { maxDaysInDayRangeMultiRoomSearch } = config;
  if (!startDate || !endDate) return false;
  const diffInDays = dayjs(dayjs(endDate).format(config.formatDate)).diff(
    dayjs(dayjs(startDate).format(config.formatDate)),
    'days',
    true
  );

  if (diffInDays < 0 || !maxDaysInDayRangeMultiRoomSearch) return false;
  return diffInDays <= maxDaysInDayRangeMultiRoomSearch;
}

export class CustomPackageSearchStore extends PackageSearchStore {
  api: ApiService;
  constructor(public config: YsnConfigModel, api: ApiService, theme?: Theme) {
    super(config, theme);
    this.api = api;
  }
  validateParamsAndShowError(): void {
    const errorsKeys: string[] = [];
    const { enableMultipleRooms, maxDaysInDayRangeMultiRoomSearch } = this.config as YsnConfigModel;
    if (
      enableMultipleRooms &&
      this.searchParams.occupancy &&
      this.searchParams.occupancy?.length > 1
    ) {
      // if (!this.areDestinationsInTheSameCountry) {
      //   errorsKeys.push('pleaseOnlyOneDestination');
      // }
      if (
        maxDaysInDayRangeMultiRoomSearch &&
        maxDaysInDayRangeMultiRoomSearch > 0 &&
        !isWithinTheRange(this.config, this.searchParams.startDate, this.searchParams.endDate)
      ) {
        errorsKeys.push('dateShouldBeInTheRangeOf');
      }
    }

    if (this.searchParams.occupancy) {
      const packageStore = new PackageStore(this.api, this.config);
      const occupancy = packageStore.applyConfigOnRoomContainers(this.searchParams.occupancy);
      const totalInfants = occupancy
        .flatMap(occ => occ.infants)
        .reduce((acc, currentValue) => acc + currentValue, 0);
      const numberOfTravelers = occupancy
        .flatMap(occ => occ.adults + occ.children)
        .reduce((acc, currentValue) => acc + currentValue, 0);
      if (totalInfants > numberOfTravelers) {
        errorsKeys.push('infantNumberMustBeLessOrEqualAdultNumber');
      }
      if (numberOfTravelers > MAX_NUMBER_OF_TRAVELERS_ON_ONE_BOOKING && occupancy.length === 1) {
        errorsKeys.push('maxPaxRoomConfigurationError');
      }
    }
    this.setValidationErrorMessages(errorsKeys);
  }
}

type SearchCacheContainType = {
  response: ApiGetPackagesResponse | null | undefined;
  request: ApiGetPackagesRequest;
  timestamp: number;
};

export class CustomPackageStore extends PackageStore {
  config: YsnConfigModel;

  constructor(
    api: ApiService,
    config: YsnConfigModel,
    initialSorting?: ApiSorting,
    customSetIsLoading?: ((isLoading: boolean) => void) | undefined
  ) {
    super(api, config, initialSorting, customSetIsLoading);
    this.config = config;
  }

  async loadPackages(subType: ApiItemType, doBestPriceSearch?: boolean): Promise<void> {
    let res: ApiGetPackagesResponse | null = null;
    let error: string | null = null;
    const warning: string | null = null;

    if (this.searchParams.origin && !this.searchParams.origin.id && this.searchParams.origin.code) {
      const response = await this.api.getGeoUnitsForItemType(ApiItemType.FLIGHT);
      const origin: ApiGeoUnit | undefined = Object.values(response.geoUnits).find(
        (geoUnit: ApiGeoUnit) => geoUnit.code === this.searchParams.origin?.code
      );

      if (origin) {
        this.setSearchParams({ ...this.searchParams, origin });
      }
    }

    if (
      this.searchParams.destination &&
      !this.searchParams.destination.id &&
      this.searchParams.destination.code
    ) {
      const response = await this.api.getGeoUnitsForItemType(subType);
      const destination: ApiGeoUnit | undefined = Object.values(response.geoUnits).find(
        (geoUnit: ApiGeoUnit) => geoUnit.code === this.searchParams.destination?.code
      );

      if (origin) {
        this.setSearchParams({ ...this.searchParams, destination });
      }
    }

    if (this.searchParams.destinationItem) {
      const { destinationItem, mainServiceCodes } = this.searchParams;
      if (destinationItem.category === DestinationItemCategory.GEO_UNIT) {
        const dest = ApiGeoUnitFromJSON({
          id: destinationItem.id,
          code: destinationItem.code,
          typeCode: destinationItem.typeCode,
          children: {}
        });
        this.setSearchParams({ ...this.searchParams, destination: dest, productId: undefined });
      } else if (mainServiceCodes && mainServiceCodes.length) {
        this.setSearchParams({
          ...this.searchParams,
          productId: undefined,
          destination: undefined
        });
      } else {
        this.setSearchParams({
          ...this.searchParams,
          productId: destinationItem.id,
          destination: undefined
        });
      }
    }
    const roomContainers = this.applyConfigOnRoomContainers(this.searchParams.occupancy || []);

    const mainServiceCodes = this.searchParams.mainServiceCodes || [];
    const request = ApiGetPackagesRequestFromJSON({
      classCode: '', // what is this?
      destinationAirport: this.searchParams.destination?.id, // TODO clean up model
      destination: this.searchParams.destination?.id,
      destinations: this.searchParams.destinations
        ? this.searchParams.destinations
            .filter(destinationItem => !mainServiceCodes.includes(destinationItem.code))
            .map(destinationItem => destinationItem.id)
        : undefined,
      departureAirport: this.searchParams.origin?.id,
      endDate: this.searchParams.endDate,
      startDate: this.searchParams.startDate,
      origins: this.searchParams.origins
        ? this.searchParams.origins.map(origin => origin.id)
        : undefined,
      roomContainer: roomContainers,
      packageServiceGroup: this.searchParams.packageServiceGroup,
      subType,
      listOptions: {
        filter: this._filter || [],
        sorting: this._sorting || {},
        pagination: this._pagination || {}
      },
      packageCode: this.searchParams.packageCode,
      mainServiceCodes,
      duration: this.searchParams.duration
        ? DurationFactory.create(this.searchParams.duration)
        : undefined,
      salesChannel: this.searchParams.salesChannel,
      productId: this.searchParams.productId,
      preSelectedCharacteristics: this.searchParams?.preSelectedCharacteristics || [],
      inventories: this.searchParams?.inventories || [],
      calculatePrices: this._calculatePrices
    } as ApiGetPackagesRequest);

    if (this.config?.frontendCache?.enable) {
      res = this.getCachedRequest(
        request,
        this.config?.frontendCache?.ttl
      ) as ApiGetPackagesResponse | null;
    }

    if (res?.packages.length === 0) res = null; // Force the Request if Empty
    try {
      if (!res) {
        if (doBestPriceSearch) {
          res = await this.api.searchBestPricePackages(request);
        } else {
          res = await this.api.searchPackages(request);
        }
      }
    } catch (e) {
      this._logger.warn(e);
      error = Keys.apiNotAbleToGetPackages;
    }

    runInAction(() => {
      if (this.config?.frontendCache?.enable) {
        this.setCachedRequest(request, res);
      }

      this._packageListResponse = res;
      if (res) {
        this._resultPackageCount = res.packages.length;
        if (res.listOptions) {
          this.setListOptions(res.listOptions);
        }
      }
      this.error = error;
      this.warning = warning;
      if (this._resultPackageCount === 0) {
        this.warning = Keys.emptyResultsWarning;
        if (!!this.listCountFull && this.listCountFull > 0) {
          this.warning = Keys.emptyFilteringResultsWarning;
        }
      }
    });
  }

  getCachedRequest(
    request: ApiGetPackagesRequest,
    ttl: number
  ): ApiGetPackagesResponse | void | null {
    const cachedRequests = searchPackagesSessionCacheStorage.get();
    if (!cachedRequests) return null;
    const updatedRequests: SearchCacheContainType[] = [];
    const nowTimestamp = Date.now();
    let foundCache: SearchCacheContainType | undefined;

    const sortedObj = cloneDeep(request);
    sortedObj.listOptions = sortedObj.listOptions
      ? {
          ...(sortedObj.listOptions as ApiListOptions),
          filter: sortFilters(
            ((sortedObj.listOptions as ApiListOptions)?.filter || []) as ApiFilter[]
          )
        }
      : undefined;
    sortedObj.destinations = (sortedObj.destinations || [])?.sort();
    sortedObj.origins = (sortedObj.origins || [])?.sort();
    cachedRequests.map(cachedRequest => {
      if (!isCacheExpired(cachedRequest.timestamp, nowTimestamp, ttl)) {
        if (!foundCache) {
          const firstElement = { ...removeUndefinedMultiLevel(cachedRequest.request) };
          const secondElement = { ...removeUndefinedMultiLevel(sortedObj) };
          const isEqualValue = JSON.stringify(firstElement) === JSON.stringify(secondElement);

          if (isEqualValue) foundCache = cachedRequest;
        }
        updatedRequests.push(cachedRequest);
      }
    });
    searchPackagesSessionCacheStorage.clear();
    searchPackagesSessionCacheStorage.set(updatedRequests);
    if (foundCache) {
      return foundCache?.response;
    }
  }

  setCachedRequest(
    request: ApiGetPackagesRequest,
    response: ApiGetPackagesResponse | null
  ): ApiGetPackagesResponse | void | null {
    const cachedRequests = searchPackagesSessionCacheStorage.get() || [];

    const sortedObj = cloneDeep(request);
    sortedObj.listOptions = sortedObj.listOptions
      ? {
          ...(sortedObj.listOptions as ApiListOptions),
          filter: sortFilters(
            ((sortedObj.listOptions as ApiListOptions)?.filter || []) as ApiFilter[]
          )
        }
      : undefined;
    sortedObj.destinations = (sortedObj.destinations || [])?.sort();
    sortedObj.origins = (sortedObj.origins || [])?.sort();

    const newData: SearchCacheContainType = {
      response,
      request: sortedObj,
      timestamp: Date.now()
    };
    cachedRequests.push(newData);
    searchPackagesSessionCacheStorage.set(cachedRequests);
  }
}

function isCacheExpired(timestamp1: number, timestamp2: number, ttl: number): boolean {
  const fiveMinutesInMilliseconds = ttl * 60 * 1000;
  const difference = Math.abs(timestamp1 - timestamp2);
  return difference > fiveMinutesInMilliseconds;
}

const searchPackagesSessionCacheStorage = new SessionStorage<SearchCacheContainType[]>(
  'search-load-packages-caches'
);

const sortFilters = (filters: ApiFilter[]) =>
  filters.flatMap(filter => {
    const _filter = filter as ApiMultipleSelectionFilter | ApiGroupedMultipleSelectionFilter;
    if (filter.type === ApiFilterType.MultipleSelectionFilter) {
      const b = _filter as ApiMultipleSelectionFilter;
      return [
        { ...b, options: sortOptions(b.options), selections: sortObjectByKeys(b.selections) }
      ];
    }
    if (filter.type === ApiFilterType.GroupedMultipleSelectionFilter) {
      const a = filter as ApiGroupedMultipleSelectionFilter;
      return [{ ...a, options: sortGroupedOptions(a.options) }];
    }
    return [filter];
  });

const sortObjectByKeys = (obj: { [key: string]: string }) => {
  const sortedObj: {
    [key: string]: string;
  } = {};
  Object.keys(obj)
    .sort()
    .forEach(key => {
      sortedObj[key] = obj[key];
    });
  return sortedObj;
};

const sortGroupedOptions = (options: ApiGroupedMultipleSelectionFilter['options']) => {
  const sortedOptions = { ...options };
  for (const key of Object.keys(sortedOptions)) {
    sortedOptions[key] = sortBy(sortedOptions[key], 'value');
  }
  return sortedOptions;
};
const sortOptions = (options: any) => sortBy(options, 'value');

const removeUndefinedMultiLevel = (obj: any) => {
  if (typeof obj !== 'object' || obj === null) return obj;
  for (const key in obj) {
    if (obj[key] === undefined) {
      delete obj[key];
    } else if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      obj[key] = removeUndefinedProps(obj[key]);
    }
  }
  return obj;
};
