import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import packageJson from '../../package.json';
import YsnConfigModel from '@/Config/YsnConfigModel';
import {
  addModalHeader,
  defaultTranslations,
  getLanguageKlaro,
  loadTranslationsConfig,
  moveAnalyticsButton,
  setKlaroElements
} from '@/Tracking/klaroUtils';

export const getKlaroConfig = (lang?: string) => {
  const loadedTranslations = loadTranslationsConfig();
  const langUsed = lang || getLanguageKlaro();

  return {
    styling: {
      theme: ['light']
    },
    acceptAll: true,
    htmlTexts: true,
    noticeAsModal: true,
    lang: langUsed,
    translations:
      loadedTranslations[langUsed] !== undefined ? loadedTranslations : defaultTranslations,
    services: [
      { name: 'necessary', purposes: ['functional'], default: true, required: true },
      {
        name: 'googleAnalytics',
        purposes: ['analytics'],
        default: true,
        onAccept: function () {
          tracking?.plugins?.enable('google-tag-manager');
        },
        onDecline: function () {
          tracking?.plugins?.disable('google-tag-manager', () => null);
        }
      }
    ]
  };
};

const analyticsOptions = {
  app: packageJson.name,
  version: packageJson.version,
  debug: false,
  plugins: []
};

export let tracking = Analytics(analyticsOptions);

const removeOverlayForBlockedCookies = () => {
  const cookieModalNotice = document.querySelector('.cookie-modal-notice');
  const cookieModal = document.querySelector('.cookie-modal');
  if (cookieModalNotice && cookieModal) {
    const cookieModalNoticeStyle = window.getComputedStyle(cookieModalNotice);
    if (cookieModalNoticeStyle.display === 'none') {
      cookieModal.classList.remove('cookie-modal');
    }
  }
};

export const enableKlaro = async (): Promise<void> => {
  const Klaro = await import('klaro/dist/klaro-no-css');
  window.klaro = Klaro;
  window.klaroConfig = getKlaroConfig();
  Klaro.setup(getKlaroConfig());
  removeOverlayForBlockedCookies();
  addModalHeader();
  moveAnalyticsButton();
  setKlaroElements();
};

export const initTracking = async (config: YsnConfigModel, isDev: boolean) => {
  let consent = true;
  if (config.useConsentManager) {
    const manager = window.klaro.getManager();
    consent = manager.getConsent('googleAnalytics') && manager.confirmed;
  }

  if (config.googleTagManagerContainerId) {
    analyticsOptions.debug = isDev;
    if (
      !analyticsOptions.plugins.some((item: { name: string }) => item.name === 'google-tag-manager')
    ) {
      analyticsOptions.plugins.push(
        googleTagManager({
          containerId: config.googleTagManagerContainerId,
          enabled: consent
        }) as never
      );
    }
  }

  tracking = Analytics(analyticsOptions);
};
