import { ApiInventory } from '@ibe/api';
import { ConfigModel } from '@ibe/services';

class YsnConfigModel extends ConfigModel {
  hideCreateQuotationBookingButton?: boolean;
  enableMultipleRooms?: boolean;
  showDirectFlightsFilter?: boolean;
  affiliateAgencyNumber?: number;
  showAgencyCommunication?: boolean;
  displayStrikeThroughPrices?: boolean;
  maxDaysInDayRangeMultiRoomSearch?: number;
  internFlightInventories?: ApiInventory[];
  frontendCache?: {
    ttl: number;
    enable: boolean;
  };
}

export default YsnConfigModel;
